import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const AdelaideTimelinePage = () => {

  // const events = [
  //   {
  //     date: "",
  //     title: "",
  //     description: ""
  //   }
  // ];

  return (
    <Layout>
      <Seo
        title="Adelaide"
        description="The innovation precinct in the heart of the Riverina dedicated to research, curated collaboration and sustainable production."
      />
      <div className="flex justify-center mx-4 mb-16 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="sr-only">Adelaide</h1>
        <StaticImage
          placeholder="dominantColor"
          height={110}
          src="../../images/banner-adelaide.png"
          alt="I choose South Australia" />
      </div>
      <div className="lg:hidden">
        <StaticImage
          placeholder="dominantColor"
          src="../../images/hero-adelaide-mobile.png"
          alt="Adelaide feature" />
      </div>
      <div className="hidden w-full lg:block">
        <StaticImage
          className="w-full"
          src="../../images/hero-adelaide.png"
          placeholder="dominantColor"
          objectFit="fill"
          layout="constrained"
          alt="Adelaide feature" />
      </div>
      <ul className="mx-4 my-8 text-gray-700 lg:my-16 lg:w-full lg:mx-auto lg:max-w-screen-md">
        <li className="p-8 shadow-2xl rounded-2xl">
          <span className="px-4 py-2 text-sm font-semibold text-green-700 bg-green-200 rounded-full">2 Dec 2022</span>
          <p className="mt-4">
            Due to a strategic change our management is relocating to Canberra (details to be announced soon).
            Anomaly will relocate their headquarters to Canberra in 2023.
          </p>
          <p className="mt-4">
            We will be hiring in 2023 with positions available in Canberra, Adelaide and Wagga Wagga.
            Please keep an eye out on our careers page for more information.
          </p>
          <p className="mt-4">
            Anomaly is committed to operating in the beautiful city of Adelaide, and considers the city a
            strategic location for the business.
          </p>
          <p className="mt-4">
            We wanted to thank the staff at The Hub in Adelaide for housing us this year and hope to
            continue our relationship with them in the future.
          </p>
        </li>

        <li className="p-8 mt-16 shadow-2xl rounded-2xl">
          <span className="px-4 py-2 text-sm font-semibold text-green-700 bg-green-200 rounded-full">4 Apr 2022</span>
          <p className="mt-4">
            Anomaly will be operating our of <a href="https://www.hubaustralia.com">The Hub</a> on Pirie Street, Adelaide, SA.
          </p>
        </li>
        <li className="p-8 mt-16 shadow-2xl rounded-2xl">
          <span className="px-4 py-2 text-sm font-semibold text-green-700 bg-green-200 rounded-full">21 Feb 2022</span>
          <p className="mt-4">
            As restrictions have eased, we've inspected several office spaces around the Adelaide CBD
            to establish a formal presence.
          </p>
        </li>
        <li className="p-8 mt-16 mb-6 shadow-2xl rounded-2xl">
          <span className="px-4 py-2 text-sm font-semibold text-green-700 bg-green-200 rounded-full">16 Jan 2022</span>
          <p className="mt-4">
            Our senior staff moved from Wagga Wagga to Adelaide with the aim of establishing our second presence. After a slight
            delay due to getting infected with COVID, our team is operational.
          </p>
        </li>
      </ul>

    </Layout>
  );
};
export default AdelaideTimelinePage
